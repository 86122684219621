import React from "react"
import './DownloadSave.scss'

const DownloadSave = ({ downloadSaveContent }) => {

  // const analyticsEvent = (eventWebEventName, eventWebEventData, eventConversionName, eventConversionData) => {
  //   if (typeof window.gtag !== 'undefined') {
  //     if(eventWebEventName) {
  //       window.gtag(eventWebEventName, "click", {send_to: "G-R17BKG6NML", ...eventWebEventData})
  //     }
  //     if(eventConversionName) {
  //       window.gtag(eventConversionName, "click", {send_to: "G-R17BKG6NML", ...eventConversionData})
  //     } 
  //   }
  // }

  const isBrowser = typeof window !== "undefined"

  if(downloadSaveContent) {
  return (
    <section className="downloadSave">
      <div className="downloadSave-copy">
        <h2>
          {downloadSaveContent.downloadAndSaveTitle}
          {/* {(downloadSaveContent.countryCode === "DE" && isBrowser)  &&
            <span className="downloadSave-copy downloadSave-copy-small">(*abhängig von den Aufladewerten)</span>
          } */}
        </h2>
        <p>{downloadSaveContent.downloadAndSaveSubtitle}</p>
        <div className="downloadSave-buttons">
          {/* <a href={downloadSaveContent.appleStoreLink} target="_blank" rel="noreferrer" onClick={analyticsEvent("click_appstore", downloadSaveContent.appleStoreLink, "outboundclick_appstore", {id: '703389461', label: 'EP_HCN29g8gDEJW-s88C'})}><img src={downloadSaveContent.appleStoreImage.url} alt={downloadSaveContent.appleStoreImage.description} /></a>
          <a href={downloadSaveContent.googlePlayLink} target="_blank" rel="noreferrer" onClick={analyticsEvent("click_playstore", downloadSaveContent.appleStoreLink, "outboundclick_playstore", {id: '703389461', label: 'EP_HCN29g8gDEJW-s88C'})}><img src={downloadSaveContent.googlePlayImage.url} alt={downloadSaveContent.googlePlayImage.description} /></a> */}
          <a className="click_appstore" href={downloadSaveContent.appleStoreLink} target="_blank" rel="noreferrer"><img className="click_appstore" src={downloadSaveContent.appleStoreImage.url} alt={downloadSaveContent.appleStoreImage.description} /></a>
          <a className="click_playstore" href={downloadSaveContent.googlePlayLink} target="_blank" rel="noreferrer"><img className="click_playstore" src={downloadSaveContent.googlePlayImage.url} alt={downloadSaveContent.googlePlayImage.description} /></a>
        </div>
      </div>
      <div className="downloadSave-image">
        {/* Conditional Statements to control link display for GB only */}
        {downloadSaveContent.downloadAndSaveImage.url.length > 0 && (downloadSaveContent.countryCode !== "GB" && isBrowser) &&
          <img src={downloadSaveContent.downloadAndSaveImage.url} alt={downloadSaveContent.downloadAndSaveImage.description} />
        }
        {downloadSaveContent.downloadAndSaveImage.url.length > 0 && (downloadSaveContent.countryCode === "GB" && isBrowser) &&
          <a href="https://imocarwash.com/gb/ultra-hd/" target="_blank" rel="noreferrer">
              <img src={downloadSaveContent.downloadAndSaveImage.url} alt={downloadSaveContent.downloadAndSaveImage.description} />
          </a>
        }
        {/* {downloadSaveContent.downloadAndSaveVideo.url.length > 0 &&
          <video width="568" height="545" muted="true" autoplay="true" loop="true" playsinline="true">
            <source src={downloadSaveContent.downloadAndSaveVideo.url} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        } */}
      </div>
    </section>
  )
  } else {
    return (
      <div>Loading</div>
    )
  }

}
export default DownloadSave;
